export const onboardingConstants = {   

    OLIVER_LOGIN_SUCCESS: 'OLIVER_EXTERNAL_LOGIN_SUCCESS',
    OLIVER_LOGIN_FAILURE: 'OLIVER_EXTERNAL_LOGIN_FAILURE',
    OLIVER_LOGIN_REQUEST: 'OLIVER_EXTERNAL_LOGIN_REQUEST',

    GET_PROFILE_REQUEST: 'GET_PROFILE_REQUEST',
    GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',
    GET_PROFILE_FAILURE: 'GET_PROFILE_FAILURE',
    
};