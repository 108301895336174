export const cloudPrinterConstants = {
 
    GET_CLOUD_PRINTER_REQUEST: 'GET_CLOUD_PRINTER_REQUEST',
    GET_CLOUD_PRINTER_SUCCESS: 'GET_CLOUD_PRINTER_SUCCESS',
    GET_CLOUD_PRINTER_FAILURE: 'GET_CLOUD_PRINTER_FAILURE',

    SET_CLOUD_PRINTER_REQUEST: 'SET_CLOUD_PRINTER_REQUEST',
    SET_CLOUD_PRINTER_SUCCESS: 'SET_CLOUD_PRINTER_SUCCESS',
    SET_CLOUD_PRINTER_FAILURE: 'SET_CLOUD_PRINTER_FAILURE',
};
