export const checkShopStatusConstants = {
 
    SHOPSTATUS_REQUEST: 'CHECK_SHOPSTATUS_REQUEST',
    SHOPSTATUS_SUCCESS: 'CHECK_SHOPSTATUS_SUCCESS',
    SHOPSTATUS_FAILURE: 'CHECK_SHOPSTATUS_FAILURE',
    SHOPSTATUS_REFRESH: 'CHECK_SHOPSTATUS_REFRESH',
};
export const getProductCountConstants = {
 
    PRODUTCTCOUNT_REQUEST: 'GET_PRODUTCTCOUNT_REQUEST',
    PRODUTCTCOUNT_SUCCESS: 'GET_PRODUTCTCOUNT_SUCCESS',
    PRODUTCTCOUNT_FAILURE: 'GET_PRODUTCTCOUNT_FAILURE',
    PRODUTCTCOUNT_REFRESH: 'GET_PRODUTCTCOUNT_REFRESH',
};

export const getBridgeSettingsConstants = {
 
    BRIDGESETTINGS_REQUEST: 'GET_BRIDGESETTINGS_REQUEST',
    BRIDGESETTINGS_SUCCESS: 'GET_BRIDGESETTINGS_SUCCESS',
    BRIDGESETTINGS_FAILURE: 'GET_BRIDGESETTINGS_FAILURE',
};