export const favouriteListConstants = {
    
    FAVOURITE_LIST_REQUEST: 'FAVOURITE_LIST_REQUEST',
    FAVOURITE_LIST_SUCCESS: 'FAVOURITE_LIST_SUCCESS',
    FAVOURITE_LIST_FAILURE: 'FAVOURITE_LIST_FAILURE',
    
    FAVOURITE_CHILD_CATEGORY_LIST_REQUEST:'FAVOURITE_CHILD_CATEGORY_LIST_REQUEST',
    FAVOURITE_CHILD_CATEGORY_LIST_SUCCESS:'FAVOURITE_CHILD_CATEGORY_LIST_SUCCESS',
    FAVOURITE_CHILD_CATEGORY_LIST_FAILURE:'FAVOURITE_CHILD_CATEGORY_LIST_FAILURE',

    FAVOURITE_GET_SUBATTRIBUTES_LIST_REQUEST:'FAVOURITE_GET_SUBATTRIBUTES_LIST_REQUEST',
    FAVOURITE_GET_SUBATTRIBUTES_LIST_SUCCESS:'FAVOURITE_GET_SUBATTRIBUTES_LIST_SUCCESS',
    FAVOURITE_GET_SUBATTRIBUTES_LIST_FAILURE:'FAVOURITE_GET_SUBATTRIBUTES_LIST_FAILURE',

    SAVE_LIST_REQUEST:'FAVOURITE_SAVE_LIST_REQUEST',
    SAVE_LIST_SUCCESS:'FAVOURITE_SAVE_LIST_SUCCESS',
    SAVE_LIST_FAILURE:'FAVOURITE_SAVE_LIST_FAILURE',

    GET_VARIATION_PRODUCT_LIST_SUCCESS:'GET_VARIATION_PRODUCT_LIST_SUCCESS',

    FAVOURITE_PRODUCT_DELETE_REQUEST:'FAVOURITE_PRODUCT_DELETE_REQUEST',
    FAVOURITE_PRODUCT_DELETE_SUCCESS:'FAVOURITE_PRODUCT_DELETE_SUCCESS',
    FAVOURITE_PRODUCT_DELETE_FAILURE:'FAVOURITE_PRODUCT_DELETE_FAILURE',
};
