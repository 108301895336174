export const activityConstants = {

    GETALL_REQUEST: 'ACTIVITY_GETALL_REQUEST',
    GETALL_SUCCESS: 'ACTIVITY_GETALL_SUCCESS',
    GETALL_FAILURE: 'ACTIVITY_GETALL_FAILURE',
    
    GETONE_REQUEST: 'GETONE_REQUEST',
    GETONE_SUCCESS: 'GETONE_SUCCESS',
    GETONE_FAILURE: 'GETONE_FAILURE',
   
    GET_DETAIL_REQUEST: 'DETAIL_ORDER_REQUEST',
    GET_DETAIL_SUCCESS: 'DETAIL_ORDER_SUCCESS',
    GET_DETAIL_FAILURE: 'DETAIL_ORDER_FAILURE',

    GET_FILTER_ORDER_LIST_SUCCESS:'GET_FILTER_ORDER_LIST_SUCCESS',
     
    SEND_EMAIL_REQUEST:'SEND_MAIL_REQUEST',
    SEND_EMAIL_SUCCESS:'SEND_MAIL_SUCCESS',
    SEND_EMAIL_FAILURE:'SEND_MAIL_FAILURE',

    GET_FILTER_REQUEST: 'ACTIVITY_FILTER_REQUEST',
    GET_FILTER_SUCCESS: 'ACTIVITY_FILTER_SUCCESS',
    GET_FILTER_FAILURE: 'ACTIVITY_FILTER_FAILURE',


    ORDER_DELETE_REQUEST: 'ORDER_DELETE_REQUEST',
    ORDER_DELETE_SUCCESS: 'ORDER_DELETE_SUCCESS',
    ORDER_DELETE_FAILURE: 'ORDER_DELETE_FAILURE',

};
