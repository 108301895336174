export const saveCustomerInOrderConstants = { 
    
    SCIO_REQUEST: 'SCIO_REQUEST',
    SCIO_SUCCESS: 'SCIO_SUCCESS',
    SCIO_FAILURE: 'SCIO_FAILURE',
    SCIO_REFRESH: 'SCIO_REFRESH', 
    
    SEND_MAIL_TEMP_ORDER_SUCCESS:'SEND_MAIL_TEMP_ORDER_SUCCESS',
    SEND_MAIL_TEMP_ORDER_FAILURE:'SEND_MAIL_TEMP_ORDER_FAILURE',
    SEND_MAIL_TEMP_ORDER_REQUEST:'SEND_MAIL_TEMP_ORDER_REQUEST'
};
