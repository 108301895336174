export const taxRateConstants = {

    GETALL_REQUEST: 'TAX_RATE_GETALL_REQUEST',
    GETALL_SUCCESS: 'TAX_RATE_GETALL_SUCCESS',
    GETALL_FAILURE: 'TAX_RATE_GETALL_FAILURE',
    GETALL_REFRESH: 'TAX_RATE_GETALL_REFRESH',

    GET_TAXSETTING_REQUEST: 'TAX_RATE_SETTING_REQUEST',
    GET_TAXSETTING_SUCCESS: 'TAX_RATE_SETTING_SUCCESS',
    GET_TAXSETTING_FAILURE: 'TAX_RATE_SETTING_SUCCESS',

    GET_TAXLIST_REQUEST:'GET_TAXLIST_REQUEST',
    GET_TAXLIST_SUCCESS:'GET_TAXLIST_SUCCESS',

    GET_TAX_RATES_REQUEST:'GET_TAX_RATES_REQUEST',
    GET_TAX_RATES_SUCCESS:'GET_TAX_RATES_SUCCESS',
    GET_TAX_RATES_FAILURE:'GET_TAX_RATES_FAILURE',
    
    CHECK_MULTIPLE_TAX_RATES_REQUEST:'CHECK_MULTIPLE_TAX_RATES_REQUEST',
    CHECK_MULTIPLE_TAX_RATES_SUCCESS:'CHECK_MULTIPLE_TAX_RATES_SUCCESS'
};
