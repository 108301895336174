export const pinLoginConstants = {

    PIN_LOGIN_REQUEST: 'USERS_PIN_LOGIN_REQUEST',
    PIN_LOGIN_SUCCESS: 'USERS_PIN_LOGIN_SUCCESS',
    PIN_LOGIN_FAILURE: 'USERS_PIN_LOGIN_FAILURE',

    LOGOUT: 'PIN_USERS_LOGOUT',

    SUBCRIPSTION_STATUS_REQUEST: "SUBCRIPSTION_STATUS_REQUEST",
    SUBCRIPSTION_STATUS_SUCCESS: "SUBCRIPSTION_STATUS_SUCCESS",
    SUBCRIPSTION_STATUS_FAILURE: "SUBCRIPSTION_STATUS_FAILURE",

    GET_VERSION_REQUEST: "GET_VERSION_REQUEST",
    GET_VERSION_SUCCESS: "GET_VERSION_SUCCESS",
    GET_VERSION_FAILURE: "GET_VERSION_FAILURE",

    CREATE_PIN_REQUEST: "CREATE_PIN_REQUEST",
    CREATE_PIN_SUCCESS: "CREATE_PIN_SUCCESS",
    CREATE_PIN_FAILURE: "CREATE_PIN_FAILURE"
};
