import React from 'react';

export const LoadingModal = () => {
    return (
        <div className='popup'>
            <div className="loaderThemeBg">
                <div className="preloaderbackground">
                    {/* <img src="assets/img/frame02-03s.gif" /> */}
                    <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" width="120" height="120" viewBox="0 0 400 400" enableBackground="new 0 0 400 400">
                        <g>
                            <rect x="249.28" y="156.01" className="st0 ologo-1" width="103.9" height="103.9"></rect>
                            <path id="teal" className="st1 ologo-2" d="M249.28,363.81V259.91h103.9C353.17,317.29,306.66,363.81,249.28,363.81z"></path>
                            <rect id="cyan" x="145.38" y="259.91" className="st2 ologo-3" width="103.9" height="103.89"></rect>
                            <path id="blue" className="st3 ologo-4" d="M41.49,259.91L41.49,259.91h103.9v103.89C88,363.81,41.49,317.29,41.49,259.91z"></path>
                            <rect id="purple" x="41.49" y="156.01" className="st4 ologo-5" width="103.9" height="103.9"></rect>
                            <path id="red" className="st5 ologo-6" d="M41.49,156.01L41.49,156.01c0-57.38,46.52-103.9,103.9-103.9v103.9H41.49z"></path>
                            <rect id="orange" x="145.38" y="52.12" className="st6 ologo-7" width="103.9" height="103.9"></rect>
                            <path id="yellow" className="st7 ologo-8" d="M281.3,123.99V20.09c57.38,0,103.9,46.52,103.9,103.9H281.3z"></path>
                        </g>
                    </svg>
                    <div>
                        <span className="fn-poppins">Loading...</span>
                    </div> 
                </div>
            </div>
        </div>
        // <div className='popup active' style={{ backgroundColor: 'rgba(81, 80, 80, .51)', display: 'block', zIndex: 3 }}>
        //     <div className="loaderThemeBg">
        //         <div className="preloaderbackground">
        //             {/* <img src="assets/img/frame02-03s.gif" /> */}
        //             <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" width="120" height="120" viewBox="0 0 400 400" enableBackground="new 0 0 400 400">
        //                 <g>
        //                     <rect x="249.28" y="156.01" className="st0 ologo-1" width="103.9" height="103.9"></rect>
        //                     <path id="teal" className="st1 ologo-2" d="M249.28,363.81V259.91h103.9C353.17,317.29,306.66,363.81,249.28,363.81z"></path>
        //                     <rect id="cyan" x="145.38" y="259.91" className="st2 ologo-3" width="103.9" height="103.89"></rect>
        //                     <path id="blue" className="st3 ologo-4" d="M41.49,259.91L41.49,259.91h103.9v103.89C88,363.81,41.49,317.29,41.49,259.91z"></path>
        //                     <rect id="purple" x="41.49" y="156.01" className="st4 ologo-5" width="103.9" height="103.9"></rect>
        //                     <path id="red" className="st5 ologo-6" d="M41.49,156.01L41.49,156.01c0-57.38,46.52-103.9,103.9-103.9v103.9H41.49z"></path>
        //                     <rect id="orange" x="145.38" y="52.12" className="st6 ologo-7" width="103.9" height="103.9"></rect>
        //                     <path id="yellow" className="st7 ologo-8" d="M281.3,123.99V20.09c57.38,0,103.9,46.52,103.9,103.9H281.3z"></path>
        //                 </g>
        //             </svg>
        //             <div>
        //                 <span className="">Loading...</span>
        //             </div> 
        //         </div>
        //     </div>
        // </div>
    );
}