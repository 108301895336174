export const paymentConstants = {
    PAYCONIQ_PAYMENTS_REQUEST:'PAYCONIQ_PAYMENTS_REQUEST',
    PAYCONIQ_PAYMENTS_SUCCESS:'PAYCONIQ_PAYMENTS_SUCCESS',
    PAYCONIQ_PAYMENTS_FAILURE:'PAYCONIQ_PAYMENTS_FAILURE',

    PAYCONIQ_STATUS_REQUEST:'PAYCONIQ_STATUS_REQUEST',
    PAYCONIQ_STATUS_SUCCESS:'PAYCONIQ_STATUS_SUCCESS',
    PAYCONIQ_STATUS_FAILURE:'PAYCONIQ_STATUS_FAILURE',

    PAYCONIQ_CANCEL_REQUEST:'PAYCONIQ_CANCEL_REQUEST',
    PAYCONIQ_CANCEL_SUCCESS:'PAYCONIQ_CANCEL_SUCCESS',
    PAYCONIQ_CANCEL_FAILURE:'PAYCONIQ_CANCEL_FAILURE',
};
