export const cartProductConstants = {
 
    PRODUCT_ADD_REQUEST: 'PRODUCT_ADD_REQUEST',
    PRODUCT_ADD_SUCCESS: 'PRODUCT_ADD_SUCCESS',
    PRODUCT_ADD_FAILURE: 'PRODUCT_ADD_FAILURE',
    
    SELECTED_PRODUCT_DISCOUNT_REQUEST: 'SELECTED_PRODUCT_DISCOUNT_REQUEST',
    SELECTED_PRODUCT_DISCOUNT_SUCCESS: 'SELECTED_PRODUCT_DISCOUNT_SUCCESS',
    SELECTED_PRODUCT_DISCOUNT_FAILURE: 'SELECTED_PRODUCT_DISCOUNT_FAILURE',

    PRODUCT_SINGLE_ADD_REQUEST:'PRODUCT_SINGLE_ADD_REQUEST',
    PRODUCT_SINGLE_ADD_SUCCESS: 'PRODUCT_SINGLE_ADD_SUCCESS',
    PRODUCT_SINGLE_ADD_FAILURE: 'PRODUCT_SINGLE_ADD_FAILURE',

    GET_SINGAL_INVENTORY_REQUEST: 'GET_SINGAL_INVENTORY_REQUEST',
    GET_SINGAL_INVENTORY_SUCCESS: 'GET_SINGAL_INVENTORY_SUCCESS',
    GET_SINGAL_INVENTORY_FAILURE: 'GET_SINGAL_INVENTORY_FAILURE',

    SHOW_SELECTED_PRODUCT_REQUEST:'SHOW_SELECTED_PRODUCT_REQUEST',
    SHOW_SELECTED_PRODUCT_SUCCESS:'SHOW_SELECTED_PRODUCT_SUCCESS',

    TAXLIST_GET_REQUEST:'TAXLIST_GET_REQUEST',
    TAXLIST_GET_SUCCESS:'TAXLIST_GET_SUCCESS',

    UPDATE_TAX_RATE_LIST_REQUEST:'UPDATE_TAX_RATE_LIST_REQUEST',
    UPDATE_TAX_RATE_LIST_SUCCESS:'UPDATE_TAX_RATE_LIST_SUCCESS',

    GET_TICKET_SEATING_FORM_REQUEST:'GET_TICKET_SEATING_FORM_REQUEST',
    GET_TICKET_SEATING_FORM_SUCCESS:'GET_TICKET_SEATING_FORM_SUCCESS',
    GET_TICKET_SEATING_FORM_FAILURE:'GET_TICKET_SEATING_FORM_FAILURE',
    
    RESERVED_TIKERA_SEAT_REQUEST:'RESERVED_TIKERA_SEAT_REQUEST',
    RESERVED_TIKERA_SEAT_SUCCESS:'RESERVED_TIKERA_SEAT_SUCCESS',
    RESERVED_TIKERA_SEAT_FAILURE:'RESERVED_TIKERA_SEAT_FAILURE',

    BOOKED_SEATS_SUCCESS:'BOOKED_SEATS_SUCCESS',
    BOOKED_SEATS_REQUEST:'BOOKED_SEATS_REQUEST'   
};
