import React from 'react';
import Config from '../../Config';
import LocalizedLanguage from '../../settings/LocalizedLanguage';
import { initScreenSaver, getScreenSaverImages, getTitle, getScreenSaverBtnImage, _key } from '../../settings/SelfCheckoutSettings'
const ScreenSaver = (props) => {
	let btnTitle = getTitle(_key.LABEL_FOR_BOTTOM_AREA_ON_SCREEN_SAVER);
	let btnTitleColor = getTitle(_key.BUTTON_LABEL_FONT_COLOR);
	let btnBackColor = getTitle(_key.BOTTOM_BUTTON_COLOR);
	let bottomImage = getScreenSaverBtnImage();
	let screenSaverImages = getScreenSaverImages();
	const imgError = () => {
		var img = document.getElementById('sSTopImg');
		img && img.remove();
	}
	return (
		<React.Fragment>
			<div className="idle-screen hide">
				<div className="cover-screen-saver"></div>
				<div className="body">
					<p className="bold">{LocalizedLanguage.stillThere}</p>
					<div className="icon-container">
						<img src="../assets/img/hand.gif" alt="" />
					</div>
					<p className='idle-screen-p'>{LocalizedLanguage.screenSaverLine1}<br /> {LocalizedLanguage.screenSaverLine2}<br /> {LocalizedLanguage.screenSaverLine21}</p>
					<div className="text-row">
						<p>{LocalizedLanguage.sreenSaverLine3}</p>
						<p id="timeoutNumber">30</p>
						<p>{LocalizedLanguage.screenSaverLine4}</p>
					</div>
					<button>{LocalizedLanguage.continueShopping}</button>
				</div>
			</div>
			<div id="screensaver" className={props && props.hide == true ? "screensaver hide" : "screensaver"}>
				{
					screenSaverImages && screenSaverImages.Value != "" && screenSaverImages.Value != null ?
						<img id="sSTopImg" onError={() => imgError()} className={"front"} key={'ssaver_' + 0} src={Config.key.RECIEPT_IMAGE_DOMAIN + screenSaverImages.Value} alt="" />
						: null
				}
				<div className="footer" style={{ backgroundColor: btnBackColor }}>
					<img src={bottomImage && bottomImage.Value ? (Config.key.RECIEPT_IMAGE_DOMAIN + bottomImage.Value) : ""} alt="" />
					<p style={{ color: btnTitleColor }}>{btnTitle ? btnTitle : LocalizedLanguage.tapToBegin}</p>
				</div>
				<div style={{ display: "none" }}>{
					setTimeout(() => {
						initScreenSaver()
					}, 1000)}</div>
			</div>
		</React.Fragment>
	)
}
export default ScreenSaver;
