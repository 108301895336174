export const firebaseAdminConstants = {
    SEND_FIREBASE_TOKEN_REQUEST: 'SEND_FIREBASE_TOKEN_REQUEST',
    SEND_FIREBASE_TOKEN_SUCCESS: 'SEND_FIREBASE_TOKEN_SUCCESS',
    SEND_FIREBASE_TOKEN_FAILURE: 'SEND_FIREBASE_TOKEN_FAILURE',
    

    FIREBASE_ADMIN_REQUEST: 'USERS_FIREBASE_ADMIN_REQUEST',
    FIREBASE_ADMIN_SUCCESS: 'USERS_FIREBASE_ADMIN_SUCCESS',
    FIREBASE_ADMIN_FAILURE: 'USERS_FIREBASE_ADMIN_FAILURE',
    
    FIREBASE_ADMIN_REGISTER_REQUEST: 'FIREBASE_ADMIN_REGISTER_REQUEST',
    FIREBASE_ADMIN_REGISTER_SUCCESS: 'FIREBASE_ADMIN_REGISTER_SUCCESS',
    FIREBASE_ADMIN_REGISTER_FAILURE: 'FIREBASE_ADMIN_REGISTER_FAILURE',

    FIREBASE_ADMIN_PING_REGISTER_REQUEST: 'FIREBASE_ADMIN_PING_REGISTER_REQUEST',
    FIREBASE_ADMIN_PING_REGISTER_SUCCESS: 'FIREBASE_ADMIN_PING_REGISTER_SUCCESS',
    FIREBASE_ADMIN_PING_REGISTER_FAILURE: 'FIREBASE_ADMIN_PING_REGISTER_FAILURE',
};
