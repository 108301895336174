// import { getCompanyName, getShopLogo, getShopName, get_employeeId, get_regId, get_UDid, get_userName, getUserShopLogo } from '../../common/localSettings';
// import { store } from '../../../app/store'
import Config from '../Config'
import { getCompanyName, getShopLogo, getUserShopLogo, get_employeeId, get_regId, get_userName } from '../WrapperSettings/CommonWork';
import { sendDataToWrapper } from '../settings/AndroidIOSConnect';
// import { isMobileOnly, isIOS } from "react-device-detect";


// import { WrapperReceipt } from './apps';
// import { productPriceUpdate, RawProductData, sendProductQuantity } from './apps/productApp';
// import { UpdateRecentUsedApp } from '../commonFunctions/AppDisplayFunction';
// import { EodPrint } from '../EodPrint';
import { postmessage } from './commonAppHandler';
// var JsBarcode = require('jsbarcode');
// var print_bar_code;
// export const textToBase64Barcode = (text) => {
//   var canvas = document.createElement("canvas");
//   JsBarcode(canvas, text, {
//     format: "CODE39", displayValue: false, width: 1,
//     height: 30,
//   });
//   print_bar_code = canvas.toDataURL("image/png");
//   return print_bar_code;
// }

export const handleAppEvent = (value, whereToview, isbackgroudApp = false, navigate = null) => {
  console.log("value", value)
  var jsonMsg = value ? value : '';
  var clientEvent = jsonMsg && jsonMsg !== '' && jsonMsg.command ? jsonMsg.command.toLowerCase() : '';
  console.log("jsonMsg", jsonMsg)
  console.log("clientEvent", clientEvent)
  var appResponse = '';

  if (clientEvent && clientEvent !== '') {
    // console.log("clientEvent", jsonMsg)
    //this.setState({ showNewAppExtension:true})
    // switch (clientEvent) {
    //   // common apps----------------------------------
    //   case ("appReady").toLowerCase():  //working
    //     appReady(whereToview, isbackgroudApp, isbackgroudApp) //done
    //     break;
    //   //-------3.0----------------------------
    //   case ("ReceiptDetail").toLowerCase():
    //     appResponse = WrapperReceipt(jsonMsg, whereToview)
    //     break;
    //   case ("getStoreDetails").toLowerCase():
    //     appResponse = getStoreDetails(jsonMsg, whereToview)
    //     break;
    //   case ("openCashDrawer").toLowerCase():
    //     appResponse = openCashDrawer(jsonMsg, whereToview)
    //     break;
    //   case ("getEndOfDayDetails").toLowerCase():
    //     appResponse = getEndOfDayDetails(jsonMsg, whereToview)
    //     break;
    //   //---------------------------------------
    //   default: // extensionFinished
    //     var clientJSON = {
    //       command: jsonMsg.command,
    //       version: jsonMsg.version,
    //       method: jsonMsg.method,
    //       status: 406,
    //       error: "Invalid Command" //GR[2]
    //     }
    //     postmessage(clientJSON)
    //     console.error('App Error : Extension event does not match ', jsonMsg)
    //     break;
    // }
    return appResponse;
  }
}
export const appReady = (whereToview, isbackgroudApp) => {
  var clientDetails = localStorage.getItem('clientDetail') ?
    JSON.parse(localStorage.getItem('clientDetail')) : 0
  var client_guid = clientDetails && clientDetails.subscription_detail ? clientDetails.subscription_detail.client_guid : ''

  if (whereToview == 'CheckoutView') {
    var clientJSON = {
      command: "appReady",
      version: "1.0",
      method: "get",
      status: 200,
      data:
      {
        clientGUID: client_guid,
        view: whereToview,
        privilege: clientDetails && clientDetails.user_role,
        viewport: isMobileOnly == true ? "Mobile" : "desktop"
      },
      error: null
    }
    postmessage(clientJSON)
  } else if (whereToview == 'CustomerView') {
    //var UID = get_UDid('UDID');
    //store.dispatch(customerActions.getAllEvents(UID));
    setTimeout(() => {
      const state = store.getState();
      console.log("state", state)
      if (state.single_cutomer_list && state.single_cutomer_list.items && state.single_cutomer_list.items.content) {
        var _CustomerId = state.single_cutomer_list.items.content.customerDetails.WPId;
        var clientJSON = {
          command: "appReady",
          version: "1.0",
          method: "get",
          status: 200,
          data:
          {
            CustomerId: _CustomerId,
            clientGUID: client_guid,
            view: whereToview,
            privilege: clientDetails && clientDetails.user_role,
            viewport: isMobileOnly == true ? "Mobile" : "desktop"
          },
          error: null
        }
        postmessage(clientJSON)
      }
    }, 1000);
  } else if (whereToview == 'ProductView') {  // this is not in used. 
    var clientJSON = {
      command: "appReady",
      version: "1.0",
      method: "get",
      status: 200,
      data:
      {
        ProductId: 445667,
        view: whereToview,
        privilege: clientDetails && clientDetails.user_role,
        viewport: isMobileOnly == true ? "Mobile" : "desktop"
      },
      error: null
    }
    postmessage(clientJSON)
    console.log("clientJSON from shopview", clientJSON)
  } else {  //home
    var clientJSON = {
      command: "appReady",
      version: "1.0",
      method: "get",
      status: 200,
      data:
      {
        view: whereToview,
        privilege: clientDetails && clientDetails.user_role,
        viewport: isMobileOnly == true ? "Mobile" : "desktop"
      },
      error: null
    }
    postmessage(clientJSON)
    console.log("clientJSON from shopview", clientJSON)
  }

}
export const getStoreDetails = () => {
  var companyLogo = localStorage.getItem("companylogo_base64") ? localStorage.getItem("companylogo_base64") : Config.key.RECIEPT_IMAGE_DOMAIN + getShopLogo();
  var user_ShopLogo = localStorage.getItem("user_shoplogo_base64") ? localStorage.getItem("user_shoplogo_base64") : Config.key.RECIEPT_IMAGE_DOMAIN + getUserShopLogo();
  var clientJSON = {
    version: "3.0",
    data:
    {
      storeName: getCompanyName(),
      storeLogo: user_ShopLogo,// Company logo
      printLogo: companyLogo, //Receipt logo
      location: localStorage.getItem('LocationName') ? localStorage.getItem('LocationName') : '',
      registerId: get_regId(),
      employeeId: get_employeeId(),
      employeeName: get_userName()
    }
  }
  sendDataToWrapper("store_detail", "", clientJSON);
}
export const openCashDrawer = () => {
  sendDataToWrapper("open_cashdrawer", "", { "version": "3.0" });
}

