export const checkoutConstants = {
 
    GETALL_REQUEST: 'CHECKOUT_GETALL_REQUEST',
    GETALL_SUCCESS: 'CHECKOUT_GETALL_SUCCESS',
    GETALL_FAILURE: 'CHECKOUT_GETALL_FAILURE',

    GET_CHECKOUT_LIST_SUCCESS:'GET_CHECKOUT_LIST_SUCCESS',

    INSERT_REQUEST:'CHECKOUT_INSERT_SUCCESS',
    INSERT_SUCCESS: 'CHECKOUT_INSERT_SUCCESS',
    INSERT_FAILURE: 'CHECKOUT_INSERT_FAILURE',

    ORDER_VOID_REQUEST:'ORDER_VOID_SALE_REQUEST',
    ORDER_VOID_SUCCESS:'ORDER_VOID_SALE_SUCCESS',
    ORDER_VOID_FAILURE:'ORDER_VOID_SALE_FAILURE',

    CASH_ROUNDING_REQUEST:'CASH_ROUNDING_REQUEST',
    CASH_ROUNDING_SUCCESS:'CASH_ROUNDING_SUCCESS',
    CASH_ROUNDING_FAILURE:'CASH_ROUNDING_FAILURE',

    GET_PAYMENT_TYPE_REQUEST:'GET_PAYMENT_TYPE_NAME_REQUEST',
    GET_PAYMENT_TYPE_SUCCESS:'GET_PAYMENT_TYPE_NAME_SUCCESS',
    GET_PAYMENT_TYPE_FAILURE:'GET_PAYMENT_TYPE_NAME_FAILURE',

    GLOBAL_PAYMENTS_REQUEST:'GLOBAL_PAYMENTS_REQUEST',
    GLOBAL_PAYMENTS_SUCCESS:'GLOBAL_PAYMENTS_SUCCESS',
    GLOBAL_PAYMENTS_FAILURE:'GLOBAL_PAYMENTS_FAILURE',


    TICKET_EVENT_REQUEST:'TICKET_EVENT_REQUEST',
    TICKET_EVENT_SUCCESS:'TICKET_EVENT_SUCCESS',
    TICKET_EVENT_FAILURE:'TICKET_EVENT_FAILURE',

    VOID_SALE_SUCCESS:'VOID_SALE_SUCCESS',
    
    TEMP_ORDER_SYNC_REQUEST:'TEMP_ORDER_SYNC_REQUEST',
    TEMP_ORDER_SYNC_SUCCESS:'TEMP_ORDER_SYNC_SUCCESS',
    TEMP_ORDER_SYNC_FAILURE:'TEMP_ORDER_SYNC_FAILURE',

    GET_EXTENTION_REQUEST:'GET_EXTENTION_REQUEST',
    GET_EXTENTION_SUCCESS:'GET_EXTENTION_SUCCESS',
    GET_EXTENTION_FAILURE:'GET_EXTENTION_FAILURE',

    GET_DO_SALE_REQUEST:'GET_DO_SALE_REQUEST',
    GET_DO_SALE_SUCCESS:'GET_DO_SALE_SUCCESS',
    GET_DO_SALE_FAILURE:'GET_DO_SALE_FAILURE',

    ORDERSTATUS_UPDATE_REQUEST:'ORDERSTATUS_UPDATE_REQUEST',
    ORDERSTATUS_UPDATE_SUCCESS: 'ORDERSTATUS_UPDATE_SUCCESS',
    ORDERSTATUS_UPDATE_FAILURE: 'ORDERSTATUS_UPDATE_FAILURE',

    ONLINE_PAYMENTS_REQUEST:'ONLINE_PAYMENTS_REQUEST',
    ONLINE_PAYMENTS_SUCCESS:'ONLINE_PAYMENTS_SUCCESS',
    ONLINE_PAYMENTS_FAILURE:'ONLINE_PAYMENTS_FAILURE',

};
