export const onboardingConstants = {   

    ONBOARDING_LOGIN_REQUEST: 'ONBOARDING_LOGIN_REQUEST',
    ONBOARDING_LOGIN_SUCCESS: 'ONBOARDING_LOGIN_SUCCESS',
    ONBOARDING_LOGIN_FAILURE: 'ONBOARDING_LOGIN_FAILURE',

    ENCRIPT_DATA_REQUEST: 'ENCRIPT_DATA_REQUEST',
    ENCRIPT_DATA_SUCCESS: 'ENCRIPT_DATA_SUCCESS',
    ENCRIPT_DATA_FAILURE: 'ENCRIPT_DATA_FAILURE',
    
    
        UPDATE_GO_TO_DEMO_REQUEST: 'UPDATE_GO_TO_DEMO_REQUEST',
        UPDATE_GO_TO_DEMO_SUCCESS: 'UPDATE_GO_TO_DEMO_SUCCESS',
        UPDATE_GO_TO_DEMO_FAILURE: 'UPDATE_GO_TO_DEMO_FAILURE',
    
        CHECKSHOP_CONNECTED_REQUEST: 'CHECKSHOP_CONNECTED_REQUEST',
        CHECKSHOP_CONNECTED_SUCCESS: 'CHECKSHOP_CONNECTED_SUCCESS',
        CHECKSHOP_CONNECTED_FAILURE: 'CHECKSHOP_CONNECTED_FAILURE'
};
