import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,isIOS
} from "react-device-detect";

export function showAndroidToast(udid,orderId,orderType) {
   // alert("AndroidFunctionCall ="+printBody )
   console.log("Android Call");
    if(typeof Android !== "undefined" && Android !== null) {
       // console.log("Android Call")
       // Android.showToast(toast);
        Android.makePrint(udid,orderId,orderType)
    } else if((typeof IOS !== "undefined" && IOS !== null)
              || (typeof iPhone !== "undefined" && iPhone !== null)
              || (typeof iPad !== "undefined" && iPad !== null)
              || (typeof iPod !== "undefined" && iPod !== null)
          )
    {
      // Android.showToast(toast);
      //window.webkit.messageHandlers.jsHandler.postMessage("trigger from JS")
      // IOS.makePrint(udid,orderId)
   }else if(isIOS===true)
    {
     // alert('Test IOS')
    // window.webkit.messageHandlers.jsHandler.postMessage("trigger from JS")
    // window.webkit.messageHandlers.callbackHandler.makePrint(udid,orderId);
      // IOS.makePrint(udid,orderId)
    }
  //    else if(typeof BlackBerry !== "undefined" && BlackBerry !== null) {
  //   // Android.showToast(toast);
  //   BlackBerry.makePrint(udid,orderId)
  // }  else {
  //     //  alert("Not viewing in webview");
  //   }
}
export function showAndroidReceipt(receipt,PrintReceiptData) {
  // alert("AndroidFunctionCall ="+printBody )
  console.log("Android Call");
  console.log("receipt",receipt,"PrintReceiptData",PrintReceiptData)  
   if(typeof Android !== "undefined" && Android !== null) {
        
       Android.generateReceipt(JSON.stringify(receipt),JSON.stringify(PrintReceiptData))
   }
}


export function setAndroidKeyboard(eventname) { 
  if(typeof Android !== "undefined" && Android !== null) {
   // var logout=localStorage.getItem("logoutclick");
  // alert(eventname);
  localStorage.setItem("env_type", "Android");
     Android.androidClickEvent(eventname)
 }
 
}


export function androidSearchClick() { 

  if(typeof Android !== "undefined" && Android !== null) {
   // var logout=localStorage.getItem("logoutclick");
     Android.searchClick('text')
 }
}

export function androidGetUser() { 

  if(typeof Android !== "undefined" && Android !== null) {
   // var logout=localStorage.getItem("logoutclick");
     Android.getuser()
 }
}
export function androidDisplayScreen(itemname, amount, total, mode) { 
  if((typeof Android !== "undefined" && Android !== null) && (Android.getDatafromDevice("isWrapper")==true))
  {
    // let fdata=localStorage.getItem('selected_ffdisplay');
    // if(typeof fdata!="undefined" && fdata=="yes")
    // {
      Android.displayScreen(itemname,amount,total,mode);
    // }
  }
  // else
  // {
  //   if(typeof Android !== "undefined" && Android !== null) {    
  //     Android.displayScreen(itemname,amount,total,mode)
  //   }
  // }

}
export function openCahsDrawer() { 
  console.log("openCahsDrawer");
    if(typeof Android !== "undefined" && Android !== null) {    
     Android.openCahsDrawer()
 }
}

export function AndroidExtentionFinished() { 
  console.log("extentionFinished");
    if(typeof Android !== "undefined" && Android !== null) {   
      var path = location.href
      console.log('--------path--------------',path); 
     Android.extentionFinished(path)
 }
}
export function sendDataToWrapper(method, misc, data) {
  console.log("----" + method + "-----data-->" + misc + "--->" + JSON.stringify(data))
  const Wrapper = window.Wrapper;
  if (typeof Wrapper !== "undefined" && Wrapper !== null) {
    switch (method) {
      case "store_detail":
        Wrapper.StoreDetailsListener(misc, JSON.stringify(data))
        break;
      case "receipt_detail":
        Wrapper.ReceiptDetailsListener(misc, JSON.stringify(data))
        break;
      case "eod_detail":
        Wrapper.EndOfDayDetailsListener(misc, JSON.stringify(data))
        break;
      case "cart_activity_detial":
        Wrapper.CartActivityListener(misc, JSON.stringify(data))
        break;
      case "open_cashdrawer":
        Wrapper.openCashDrawerListener(JSON.stringify(data))
        break;
      // case "store_detail":
      //   Wrapper.StoreDetailsListener(JSON.stringify(data))
      //   break;
      case "switch_user":
        Wrapper.switchUsersListener(JSON.stringify(data))
        break;
      case "current_view":
        var _viewdata = {
          version: "3.0",
          data: {
            view: misc
          }
        }
        Wrapper.viewListener(JSON.stringify(_viewdata))
        break;
      case "customer_display":
        var _images = localStorage.getItem("AdvImages") ? JSON.parse(localStorage.getItem("AdvImages")) : [];
        var _viewdata = {
          version: "3.0",
          data: {
            images: _images
          }
        }
        Wrapper.customerDisplay(JSON.stringify(_viewdata))
        break;
      case "cash_ledger_receipt":
        Wrapper.CashLedgerListener(misc, JSON.stringify(data))
        break;

      default:
        break;
    }
  }
}
export const cartActivity = (_product, action, cartproductlist = null) => {
  var data = {};
  var addons = { "name": "", "price": "" };
  var discount = { "name": _product.discount_type, "percent": _product.new_product_discount_amount, "amount": _product.product_discount_amount };
  var total = (_product.discount_type === "Number" ? _product.Price - (_product.product_discount_amount) : _product.Price - (_product.product_discount_amount * _product.quantity));
  var item = { "sku": _product.Sku, "name": _product.Title, "price": _product.Price, "descr": "", "addons": addons, "discount": discount, "total": total, "quantity": _product.quantity };
  data["version"] = "3.0";
  data["action"] = action;
  data["item"] = item;
  data["cart"] = calculateCart(cartproductlist);
  if (action == "remove") {
      data = {
          "version": "3.0", "action": "remove", "item": {
              "sku": _product.Sku,
          },
          "cart": data["cart"]
      }
  }
  sendDataToWrapper("cart_activity_detial", "", data)
}
export const calculateCart = (listItem) => {
  var _subtotal = 0.0;
  var _total = 0.0;
  var _taxAmount = 0.0;
  var _totalDiscountedAmount = 0.0;
  var _customFee = 0.0;
  var _exclTax = 0;
  var _inclTax = 0;
  var _taxId = [];
  var _taxRate = [];
  var TaxIs = [];
  var _subtotalPrice = 0.00;
  var _subtotalDiscount = 0.00;
  var _cartDiscountAmount = 0.00;
  var _productDiscountAmount = 0.00;
  var _seprateDiscountAmount = 0.00;
  var taxratelist = [];
  if ((typeof localStorage.getItem('TAXT_RATE_LIST') !== 'undefined') && localStorage.getItem('TAXT_RATE_LIST') !== null) {
      taxratelist = localStorage.getItem('TAXT_RATE_LIST') && JSON.parse(localStorage.getItem('TAXT_RATE_LIST'));
  }
  if (taxratelist && taxratelist !== null && taxratelist !== "undefined") {
      taxratelist && taxratelist.length > 0 && taxratelist.map(tax => {
          _taxId.push(tax.TaxId);
          _taxRate.push(tax.TaxRate);
          if (tax.check_is === true) {
              TaxIs.push({ [tax.TaxId]: parseFloat(tax.TaxRate) })
          }
      })
  }
  listItem && listItem.map((item, index) => {
      if (item.Price) {
          _subtotalPrice += item.Price
          _subtotalDiscount += parseFloat(item.discount_amount === null || isNaN(item.discount_amount) === true ? 0 : item.discount_amount)
          if (item.product_id) {//donothing  
              var isProdAddonsType = "";//CommonJs.checkForProductXAddons(item.product_id);// check for productX is Addons type products                  
              _exclTax += item.excl_tax ? item.excl_tax : 0;
              _inclTax += item.incl_tax ? item.incl_tax : 0;
              _cartDiscountAmount += item.cart_discount_amount;
              // _productDiscountAmount += item.discount_type === "Number" ? item.product_discount_amount:item.product_discount_amount; // quantity commment for addons
              _productDiscountAmount += item.discount_type === "Number" ? item.product_discount_amount : item.product_discount_amount * (isProdAddonsType && isProdAddonsType === true ? 1 : item.quantity);
          }
          else {
              _customFee += item.Price;
              _exclTax += item.excl_tax ? item.excl_tax : 0;
              _inclTax += item.incl_tax ? item.incl_tax : 0;
          }
      }
  })

  //total count of the prodcuts in the cart
  if (listItem && listItem.length > 0) {
      var qty = 0;
      listItem.map(item => {
          if (item && item.Price && item.Price !== "" && typeof item.product_id !== "undefined") {
              qty += item.quantity;
          }
      })
  }

  _seprateDiscountAmount = _subtotalPrice - _subtotalDiscount;
  _subtotal = _subtotalPrice - _productDiscountAmount;
  _totalDiscountedAmount = _subtotalDiscount;
  if (_taxRate) {
      _taxAmount = parseFloat(_exclTax) + parseFloat(_inclTax);
  }
  _total = parseFloat(_seprateDiscountAmount) + parseFloat(_exclTax);
  var cart = { "subtotal": _subtotal, "taxes": _taxAmount, "discount": _cartDiscountAmount, "total": _total }
  return cart;
}